html,
body {
  height: 100%;
  overflow: auto;
}
:focus:not(:focus-visible) {
  outline: none;
}

@font-face {
  font-family: 'BpmfGenSenRounded';
  src: url('https://static.kolable.com/public/fonts/BpmfGenSenRounded/BpmfGenSenRounded-R.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'BpmfGenSenRounded';
  src: url('https://static.kolable.com/public/fonts/BpmfGenSenRounded/BpmfGenSenRounded-B.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
// ant design
.ant-btn {
  span {
    line-height: normal;
  }
  .anticon {
    line-height: 0;
  }
}

.ant-col.ant-form-item-control-wrapper {
  flex-grow: 1;
}

.audio-player-popover {
  &.ant-popover {
    right: 0px;
    z-index: 10001;

    .ant-popover-title {
      padding: 1rem 1.25rem;
    }

    .ant-popover-inner-content {
      position: absolute;
      bottom: 100%;
      right: 0px;
      padding: 0;
    }

    @media (max-width: 767px) {
      .ant-popover-arrow {
        display: none;
      }
    }
  }
}

.ant-popover {
  z-index: 10001;

  .ant-popover-title {
    padding: 1rem 1.25rem;
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  @media (max-width: 767px) {
    .ant-popover-arrow {
      display: none;
    }
  }
}

.ant-modal-mask {
  z-index: 1003;
}

.ant-modal-wrap {
  z-index: 1003;
}

.ant-message {
  z-index: 2104;
}

.ant-select-dropdown {
  z-index: 1450;
}

.ant-input-number {
  width: 120px !important;
}

.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  line-height: 36px;
}

.ant-select-tree li {
  overflow: hidden;
  max-width: 432px;
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #f7f8f8;
}

// BraftEditor
.public-DraftEditorPlaceholder-root,
.public-DraftEditorPlaceholder-hasFocus {
  color: #cdcece;
}

.short-bf-content {
  height: 240px;
}

.fb_customer_chat_bubble_pop_in {
  bottom: 100px !important;
}

// Header
.ant-layout-header {
  background: #019D96;
}
header .d-flex.align-items-center a img[alt="logo"] {
  max-height: 50px;
}
header .chakra-input {
  background: #FFFFFF;
  border: none;
  outline: none;
}
header .d-flex.align-items-center > button {
  position: relative;
  color: #FFFFFF !important;
  background: #019D96 !important;
  z-index: 1;
}
header .ant-badge > button,
header .d-flex.align-items-center > div {
  color: #FFFFFF !important;
}
// 「免費體驗」按鈕
header .d-flex.align-items-center > button:first-child {
  margin-right: 1rem;
  margin-left: 1rem;
  color: #373737 !important;
}
// header .d-flex.align-items-center > button:first-child::before {
//   content: '';
//   margin-right: 5px;
//   margin-top: -2px;
//   width: 18px;
//   height: 16px;
//   background: url(./images/icon-headphone.png) center center no-repeat;
//   background-size: cover;
// }
header .d-flex.align-items-center > button:first-child::after {
  content: '';
  position: absolute;
  height: 42px;
  width: 100%;
  border-radius: 46px;
  color: #373737 !important;
  background: #FBEF50;
  z-index: -1;
}
// 漢堡選單
header .ant-btn.ant-btn-link.ant-btn-icon-only {
  color: #FFFFFF;
}
// Footer
.cwCopyrightBlock {
  width: 100%;
  margin: 50px 0 100px 0;
  text-align: center;
}
.container-post-page {
  max-width: 1000px !important;
}

.positionSticky {
  position: sticky;
  top: 100px;
}

.pageNavFixed {
  position: fixed;
  top: 65px;
}
.programPlanSticky {
  position: sticky;
  top: 40px;
}

.programPackagePlanSticky {
  position: sticky;
  top: 48px;
}

.projectPlanSticky {
  position: sticky;
  top: 110px;
}

.activityPlanSticky {
  position: sticky;
  top: 56px;
}
